body::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
  }
  
  body::-webkit-scrollbar-track {
    background: #101e40;        /* color of the tracking area */
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: #ff2e53;    /* color of the scroll thumb */
    border-radius: 10px;       /* roundness of the scroll thumb */
  }