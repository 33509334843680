@font-face {
  font-family: roboto-regular;
  src: url("../FANTASTIC\ FOUR\ MOVIE\ SLANT.ttf");
}
.btn__minting__page{

    font-family: roboto-regular !important;
    color: #fff;
    background-color: #fc0071 !important;
    vertical-align: text-top !important;
    transform: skewX(-15deg);
    border: 1.3px solid white !important;
    font-size: 1.3vw !important;
    font-weight: lighter !important;
    cursor: pointer;
    padding: 0.6rem;
    border-radius: 0px !important;
    margin: 1rem;
    letter-spacing: 1px;
}
.btn__mint{

    transform: skewX(-15deg);
    
}
#content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }

  #bg {
    position: fixed; 
    top: 0; 
    left: 0; 
      
    /* Preserve aspet ratio */
    min-width: 100%;
    min-height: 100%;
  }
  body{
      background-image: url(./Mint\ Page\ BG\ \(3\).jpg);
      background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height:100vh;  /* responsive height */
  }
  .btn-text {
    transform: skewX(15deg) !important;
    font-size: 1.2vw;
  }
  @media screen and (max-width: 800px) {
    .btn-text {
        transform: skewX(15deg) !important;
        font-size: 2.5vw !important;
      }

      .btn__minting__page{

        font-size: 3.3vw !important;
        letter-spacing: 1px;
    }

}