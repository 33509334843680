@font-face {
  font-family: roboto-regular;
  src: url("../FANTASTIC\ FOUR\ MOVIE\ SLANT.ttf");
}
@font-face {
  font-family: norobot;
  src: url("../Eina04-RegularItalic.ttf");
}
@font-face {
  font-family: exotic;
  src: url(../Exotc350BdBTBold.ttf);
}
h3 {
  font-family: exotic;
  transform: skew(-10deg);
}
h6 {
  padding-top: 5px;
}
h2 {
  font-family: roboto-regular;
  letter-spacing: 2px;
}
.countdown__header {
  display: block;
  color: rgb(228, 27, 151);
  font-size: 3rem;
  line-height: 5rem;
  text-shadow: 3px 3px 0 #1a1a1a, 4px 4px 0 #ece9e6;
  margin-top: -2rem;
}
.countdown__body {
  display: flex;
  flex-direction: column;
}
h4 {
  font-family: roboto-regular !important;
  font-weight: 800 !important;
}
.text {
  font-family: norobot;
}
.countdown__time {
  display: block;
  color: white;
  font-family: roboto-regular !important;
  font-weight: lighter !important;
  font-size: 2.8rem;
  line-height: 3.5rem;
  transform: skew(-10deg);
}
h6 {
  margin-top: -5rem;
  font-size: 1rem;
}
.countdown__container {
  padding-right: 2rem;
  position: relative;
  right: 10rem !important;
}
.part1__bg {
  background-image: url(./bg.png);
  background-size: cover;
  padding: 2rem;
}

.glow {
  color: #fff;
  text-align: center;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fc0071,
      0 0 40px #fc0071, 0 0 50px #fc0071, 0 0 60px #fc0071, 0 0 70px #fc0071;
  }

  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #fc0071, 0 0 40px #fc0071,
      0 0 50px #fc0071, 0 0 60px #fc0071, 0 0 70px #fc0071, 0 0 80px #fc0071;
  }
}

.glow__header {
  color: rgb(228, 27, 151);
  text-align: center;
  animation: glow__header 1s ease-in-out infinite alternate;
}

@keyframes glow__header {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073;
  }

  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6;
  }
}
.mint__now {
  position: relative;
  top: 2.6rem;
  cursor: pointer;
}
.btn {
  color: #fff;
  font-family: roboto-regular;
  width: fit-content;
  background-color: #fc0071 !important;
  width: 10vw;
  text-align: center;
  text-decoration: none;

  cursor: pointer;
  padding: 0.8rem !important;
  border: 1.3px solid white !important;
  letter-spacing: 2px !important;
}
.btn-text2 {
  transform: skewX(15deg) !important;
  font-size: 1.2vw;
}
.countdown__align {
  padding-right: 3rem;
}

@media screen and (max-width: 600px) {
  .text {
    margin-bottom: 1.6rem !important;
  }
  .countdown__align {
    padding-right: 0rem;
  }
  .countdown__container {
    padding-right: 0rem;
    position: relative;
    right: 0rem !important;
  }
  .presale {
  }
  h3 {
    font-size: 2rem !important;
  }
  h6 {
    font-size: 1rem !important;
    position: relative;
    top: 2rem;
  }
  .btn {
    color: #fff;
    font-family: roboto-regular;
    width: fit-content;
    background-color: #fc0071 !important;

    text-align: center;
    text-decoration: none;

    cursor: pointer;
    padding: 0.8rem !important;
    border: 1.3px solid white !important;
    letter-spacing: 2px !important;
  }
  .btn-text2 {
    font-size: 3vw;
  }
}
